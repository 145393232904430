<template>
  <div class="register-user--view">
    <div class="form">
      <div class="container">
        <div class="header--container">
          <h1>Настройки профиля</h1>
        </div>
        <div class="input--container">
          <register-input header="ID/ номер телефона:" v-model="phone" id="phone"></register-input>
        </div>
        <div class="input--container">
          <register-input header="Имя аккаунта:" v-model="name"></register-input>

        </div>
        <div class="input--container">
          <register-input header="Адрес эл.почты:" v-model="email"></register-input>

        </div>
        <div class="input--container">
          <register-input header="Придумайте пароль:" v-model="password"></register-input>

        </div>
        <div class="icon--container">
          <img src="../../assets/icons/multi.svg">
        </div>
      </div>
      <div class="container">
        <div class="addition--container">
          <div class="avatar--container">
            <p>Аватарка:</p>
            <div class="avatar-inner--container">
              <input type="file" accept=".jpg,.jpeg,.png" name="avatar" class="avatar" @change="onChangeAvatar" ref="avatarLoad">
              <img class="user__avatar" ref="avatarImage" :class="{'avatar-visible' : isAvatarImageVisible}">
              <img class="user__avatar 1" :class="{'avatar-visible' : !isAvatarImageVisible}" src="../../assets/icons/plus.png">

            </div>
          </div>
        </div>
        <div class="button--container">
          <button class="btn btn_delete" @click="showDeleteAccountModal">Удалить профиль</button>
        </div>
        <div class="buttons--container">
          <button class="btn btn__close" @click="goBack">ОТМЕНА</button>
          <button class="btn btn__action" @click="register" :class="{'not_available' : false}">ГОТОВО</button>
        </div>
      </div>
    </div>
    <cropper-image-modal v-on:close="onCloseCropper" v-on:crop="onCropAvatar" type="avatar"></cropper-image-modal>
    <delete-account-modal></delete-account-modal>

  </div>
</template>

<script>
import RegisterInput from "@/components/input/RegisterInput";
import CropperImageModal from "@/components/modal/CropperImageModal";
import IMask from "imask";
import DeleteAccountModal from "@/components/modal/DeleteAccountModal";
export default {
  name: "ChangeMultiDataView",
  components: {DeleteAccountModal, CropperImageModal, RegisterInput},
  data(){
    return {
      phone: '',
      password: '',
      email: '',
      name: '',
      avatarBase64: '',
      isAvatarImageVisible: false
    }
  },
  computed:{
    user(){
      return this.$store.getters.SELECTED_USER
    }
  },
  methods:{
    onChangeAvatar (e) {
      const files = e.target.files || e.dataTransfer.files
      if (files.length) {
        this.createImage(files[0])
      }
    },

    createImage (file) {
      this.$modal.show('CropperImageModal', {value: file})
    },

    onCloseCropper () {
      this.$refs.avatarLoad.value = ''
    },

    onCropAvatar (src) {
      this.isAvatarImageVisible = true
      this.$refs.avatarImage.src = src
      this.avatarBase64 = src.split(',')[1]
    },

    async register(){

      const res = await this.$store.dispatch("CHANGE_PROFILE",{userId: this.$route.params.id,data: {
          phone: this.phone.substr(0, 16).replace(/[^a-zA-Z0-9]/g, ''),
          name: this.name,
          password: (this.password.length === 0) ? null : this.password,
          email: this.email,
          avatar: this.avatarBase64
        }})

      if (res === 200){
        const userId = this.$route.params.id;
        this.$router.push({name : "MultiUserView",params : {id : userId}})
      }
    },


    goBack(){
      const userId = this.$route.params.id;
      this.$router.push({name : "MultiUserView",params : {id : userId}})    },

    showDeleteAccountModal(){
      this.$modal.show("DeleteAccountModal")
    }
  },
  async mounted() {
    const maskOptions = {
      mask: '+{7}(000)000-00-00'
    }

    const userId = this.$route.params.id;
    await this.$store.dispatch("GET_SELECTED_USER", {userId: userId})
    document.getElementById("phone").value = this.user.phone
    this.mask = new IMask(document.getElementById("phone"), maskOptions)


    this.name = this.user.name
    this.email = this.user.email
    this.phone = this.user.phone
    this.mask.updateValue()
    this.mask.updateControl()


  }

}
</script>

<style scoped lang="scss">
.register-user--view{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .form{
    width: 330px;
    height: 300px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
    border-radius: 4px;
    display: flex;

    @include for-big-desktop-up{
      //width: 660px;
      //height: 600px;
      width: 491px;
      height: 446px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.14);
      border-radius: 6px;

    }


    .container{
      display: flex;
      flex-direction: column;
      margin-top: 21px;

      @include for-desktop-up{
        margin-top: 15px;
      }

      .header--container{
        margin-bottom: 22px;

        @include for-desktop-up{
          margin-bottom: 14px;
        }


        h1{
          font-family: Roboto, "sans-serif";
          font-size: 18px;
          color: #2C2C2C;
          letter-spacing: 0;

          @include for-desktop-up{
            font-size: 12px;
          }
        }

        h2{
          font-family: Roboto, "sans-serif";
          font-size: 11px;
          color: #999999;
          letter-spacing: 0;
          margin-top: 4px;


          @include for-desktop-up{
            font-size: 7px;
            margin-top: 4px;
          }
        }
      }

      &:first-child{
        margin-left: 33px;

        @include for-desktop-up{
          margin-left: 21px;

        }
      }

      .input--container{
        margin-top: 15px;

        @include for-desktop-up{
          margin-top: 11px;

        }
      }

      .select--container{
        margin-bottom: 10px;

        @include for-desktop-up{
          margin-bottom: 5px;

        }
      }

      .icon--container{
        margin-top: 31px;
        @include for-desktop-up{
          margin-top: 22px;
        }

        img{
          width: 44px;
          height: 47px;

          @include for-desktop-up{
            width: 30px;
            height: 32px;
          }
        }
      }


      &:last-child{
        margin-left: 35px;

        @include for-desktop-up{
          margin-left: 26px;

        }
      }


      .addition--container{
        margin-top: 65px;
        display: flex;


        @include for-desktop-up{
          margin-top: 47px;
        }

        .map--container{
          width: 246px;
          height: 174px;
          border: 1px solid #979797;
          border-radius: 23px;
          background: #000000;
          margin-left: 120px;
          margin-top: 6px;


          @include for-desktop-up{
            width: 120px;
            height: 90px;
            margin-left: 60px;
            margin-top: 3px;
            border-radius: 12px;
          }
        }

        .avatar--container{
          margin-top: 4px;


          @include for-desktop-up{
            margin-top: 2px;
          }


          p{
            font-family: Roboto, "sans-serif";
            font-size: 11px;
            color: #2C2C2C;
            letter-spacing: 0;


            @include for-desktop-up{
              font-size: 8px;
            }
          }

          .avatar-inner--container{
            border: 1px solid #979797;
            border-radius: 6px;
            margin-top: 6px;
            width: 97px;
            height: 97px;


            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            -moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            -o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            -ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
            transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

            /* Rectangle Copy 8: */

            overflow: hidden;
            z-index: 1;

            @include for-desktop-up{
              border-radius: 4px;
              margin-top: 3px;
              width: 65px;
              height: 65px;
            }

            input{
              opacity: 0;
              position: absolute;
              width: 100%;
              left: 0;
              top:0;
              height: 97px;
              z-index: 3;

              @include for-desktop-up{
                height: 65px;
              }
            }

            .file__text{
              position: absolute;
              font-family: Roboto, sans-serif;
              font-size: 16px;
              letter-spacing: 0;
              font-weight: 300;
              color: rgba(255,255,255,0.5);
              margin: 0;
              padding: 0;
              z-index: 2;
            }

            .file__text.avatar-visible{
              display: none;
            }

            .user__avatar{
              width: 97px;
              height: 97px;
              margin: 0;
              padding: 0;
              display: none;
              object-fit: cover;

              @include for-desktop-up{
                height: 65px;
                width: 65px;
              }
            }

            .user__avatar.avatar-visible{
              display: flex;
            }

          }

        }
      }



      .buttons--container{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        margin-left: -135px;

        @include for-big-desktop-up{
          margin-top: 20px;
          margin-left: calc(-265px * 0.75);
        }


        .btn{
          width: 100px;
          height: 21px;
          background: #6F64F8;
          border-radius: 4px;
          font-family: Roboto, "sans-serif";
          font-size: 8px;
          letter-spacing: 0;
          border: 0;
          outline: 0;
          cursor: pointer;

          @include for-big-desktop-up{
            width: 150px;
            height: 32px;
            border-radius: 6px;
            font-size: 12px;
            letter-spacing: 0;
          }

          &:hover{
            filter: brightness(87.5%);
          }

          &.not_available{
            pointer-events: none;
            opacity: .5;
          }
        }

        .btn__action{
          background: #6F64F8;
          color: #FFFFFF;
        }

        .btn_delete{
          background: #D45867;
          color: #FFFFFF;


          width: 195px;
          height: 32px;

          @include for-desktop-up{
            width: 130px;
            height: 21px;
          }
        }

        .btn__close{
          background: #EDEDED;
          color: #2C2C2C;
          margin-right: 15px;

          @include for-big-desktop-up{
            margin-right: 25px;
          }
        }
      }


      .button--container{

        margin-top: 94px;
        display: flex;
        justify-content: flex-end;
        margin-left: -135px;

        @include for-big-desktop-up{
          margin-top: 140px;
          margin-left: calc(-265px * 0.75);
        }

        .btn{
          width: 100px;
          height: 21px;
          background: #6F64F8;
          border-radius: 4px;
          font-family: Roboto, "sans-serif";
          font-size: 8px;
          letter-spacing: 0;
          border: 0;
          outline: 0;
          cursor: pointer;

          @include for-big-desktop-up{
            width: 150px;
            height: 32px;
            border-radius: 6px;
            font-size: 12px;
            letter-spacing: 0;
          }

          &:hover{
            filter: brightness(87.5%);
          }

          &.not_available{
            pointer-events: none;
            opacity: .5;
          }
        }



        .btn_delete{
          background: #D45867;
          color: #FFFFFF;


          width: 195px;
          height: 32px;

          @include for-desktop-up{
            width: 130px;
            height: 21px;
          }
        }


      }
    }



  }
}



.disabled{
  pointer-events: none;
  opacity: .5;
}
</style>
